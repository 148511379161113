<template>
  <div class="entry">
    <h1>
      Vous êtes au bon endroit pour
      <br>
      <span>investir dans l’immobilier</span>
    </h1>
    <a
      :href="cleEnMainServiceUrl"
      class="hero-cta"
    >
      <uikit-button>Me faire accompagner</uikit-button>
    </a>
    <google-note-v2 class="google" />
    <div class="client-cases">
      <div class="slide-container">
        <template
          v-for="(userCase, index) in userCases"
          :key="index"
        >
          <a
            class="client-case-wrapper"
            :href="cleEnMainServiceUrl2"
          >
            <uikit-card class="client-case">
              <div class="wrapper">
                <div class="info">
                  <div class="picture">
                    <img
                      :src="userCase.picture"
                      alt=""
                    >
                  </div>
                  <div class="top">
                    <div class="title">{{ userCase.performance }}% de rendement</div>
                    <div class="identity">{{ userCase.identity }} • {{ formatPrice(userCase.price) }}</div>
                  </div>
                </div>
                <div class="tags">
                  <uikit-tag-list :tags="getInfoTags(userCase)" />
                </div>
              </div>
            </uikit-card>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UikitButton, UikitCard, UikitTagList } from '@hz/ui-kit'

const cleEnMainServiceUrl = useUrls().cleEnMainServiceUrl
const cleEnMainServiceUrl2 = useUrls().cleEnMainServiceUrl2

const _userCases = [
  {
    performance: 12,
    identity: 'Cynthia H',
    price: 160250,
    city: 'Lille',
    surface: 37,
    realEstateType: 'T2',
    regime: 'Airbnb',
    picture: '/imgs/user-cases/Cynthia.webp',
  },
  {
    performance: 8,
    identity: 'Marc B',
    price: 130000,
    city: 'Angers',
    surface: 32,
    realEstateType: 'T1',
    regime: 'Airbnb',
    picture: '/imgs/user-cases/Marc.webp',
  },
  {
    performance: 7,
    identity: 'Samuel C',
    price: 277200,
    city: 'Auterive',
    surface: 155,
    realEstateType: 'Immeuble de rapport',
    regime: '',
    picture: '/imgs/user-cases/Samuel.webp',
  },
  {
    performance: 11,
    identity: 'Rémy T',
    price: 257360,
    city: 'Lille',
    surface: 70,
    realEstateType: 'T3',
    regime: 'Colocation',
    picture: '/imgs/user-cases/Remy.webp',
  },
  {
    performance: 6,
    identity: 'Kelly T',
    price: 76000,
    city: 'St Étienne',
    surface: 40,
    realEstateType: 'T2',
    regime: 'LMNP',
    picture: '/imgs/user-cases/Kelly.webp',
  },
  {
    performance: 7,
    identity: 'Louis et Jeanne C',
    price: 320000,
    city: 'Bordeaux',
    surface: 70,
    realEstateType: 'T3',
    regime: 'Colocation',
    picture: '/imgs/user-cases/Louis-Jeanne.webp',
  },
  {
    performance: 7,
    identity: 'Alice V',
    price: 178000,
    city: 'Toulouse',
    surface: 72,
    realEstateType: 'T4',
    regime: 'Location nue',
    picture: '/imgs/user-cases/Alice.webp',
  },
]

const userCases = computed(() => [..._userCases, ..._userCases])

const formatPrice = (value) => {
  return typeof value !== 'undefined' ? `${value.toLocaleString()} €` : ''
}

const getInfoTags = (userCase) => {
  const tags = [
    { type: 'default', label: userCase.city, value: 1 },
    { type: 'default', label: `${userCase.surface}m2`, value: 2 },
    { type: 'default', label: userCase.realEstateType, value: 3 },
  ]

  if (userCase.regime) {
    tags.push({ type: 'default', label: userCase.regime, value: 4 })
  }

  return tags
}
</script>

<style lang="less" scoped>
@keyframes caseSlideAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}

.entry {
  .app-padding();
  background: var(--ds-color-primary-25);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  h1 {
    font-weight: var(--ds-weight-semi-bold);
    font-size: var(--ds-font-2xl);
    color: var(--ds-color-gray-100);
    text-align: center;

    margin-top: var(--ds-space-3xl);
    margin-bottom: var(--ds-space-3xl);

    span {
      color: var(--ds-color-primary-100);
    }
  }

  .hero-cta {
    margin-bottom: var(--ds-space-2lg);
  }

  .google {
    margin-bottom: var(--ds-space-3xl);
  }
}

.client-cases {
  display: flex;
  align-self: normal;
  margin-left: ~'calc(var(--ds-gutter-sm) * -1)';
  margin-right: ~'calc(var(--ds-gutter-sm) * -1)';
  margin-bottom: var(--ds-space-2xl);

  .slide-container {
    flex-direction: row;
    display: flex;

    gap: var(--ds-space-2lg);
    animation: caseSlideAnimation 60s linear infinite normal;
    animation-iteration-count: infinite;
    animation-play-state: running;

    &:hover {
      animation-play-state: paused;
    }
  }
}

.client-case-wrapper {
  &:first-child {
    margin-left: var(--ds-gutter-sm);
  }

  &:last-child {
    margin-right: var(--ds-gutter-sm);
  }
}

.client-case {
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: var(--ds-space-none);
    gap: var(--ds-space-md);

    .picture {
      display: flex;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      gap: var(--ds-space-lg);

      .top {
        display: flex;
        flex-direction: column;
        gap: var(--ds-space-sm);
        flex-grow: 1;
        justify-content: center;
      }

      .title {
        color: var(--ds-color-gray-100);
        line-height: var(--ds-font-sm);
        font-size: var(--ds-font-xs);
        font-weight: var(--ds-weight-semi-bold);
      }

      .identity {
        color: var(--ds-color-gray-50);
        font-size: var(--ds-font-xs);
        line-height: 10px;
        font-style: normal;
        font-weight: var(--ds-weight-regular);
        text-wrap: nowrap;
      }
    }
    .tags {
      :deep(.uikit-tag-list) {
        flex-wrap: nowrap;

        .uikit-tag {
          text-wrap: nowrap;
          font-size: 10px;
          font-weight: var(--ds-weight-medium);
        }
      }
    }
  }
}

@media @bp-desktop {
  .entry {
    h1 {
      font-weight: var(--ds-weight-semi-bold);
      font-size: var(--ds-font-3xl);

      margin-top: var(--ds-space-4xl);
      margin-bottom: var(--ds-space-4xl);
    }

    .google {
      margin-bottom: var(--ds-space-4xl);
    }
  }

  .client-cases {
    margin-left: ~'calc(var(--ds-gutter-xl) * -1)';
    margin-right: ~'calc(var(--ds-gutter-xl) * -1)';
  }

  .client-case-wrapper {
    &:first-child {
      margin-left: var(--ds-gutter-xl);
    }

    &:last-child {
      margin-right: var(--ds-gutter-xl);
    }
  }
}
</style>
