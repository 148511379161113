<template>
  <div class="google-note">
    <div class="icon">
      <img
        src="~/assets/imgs/google.png"
        alt="Note Google"
        width="24"
        height="24"
      >
    </div>
    <div class="infos u-ml-md">
      Noté {{ rating }}/5 sur Google !
    </div>
  </div>
</template>

<script setup>
const { data: googleRating } = await useLazyAsyncData(() => useGoogleApi().getRating())

const rating = computed(() => {
  const value = googleRating.value ?? 4.5

  return value.toLocaleString()
})
</script>

<style lang="less" scoped>
.google-note {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .icon {
    border-radius: 16px;
    border: 1px solid var(--ds-color-gray-25);
    background: var(--ds-color-white-100);

    width: 32px;
    height: 32px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infos {
    color: var(--ds-color-gray-50);
    font-size: var(--ds-font-sm);
    font-weight: var(--ds-weight-regular);
  }
}
</style>
