<template>
  <div id="home">
    <nuxt-lazy-hydrate when-idle>
      <home-hero id="hero" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-home-investor-lifecycle />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-home-invest-ad />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-home-invest-cities />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-community-ad />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-customer-reviews title="Des milliers d'investisseurs immobiliers nous font confiance." />
    </nuxt-lazy-hydrate>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()
const title = 'Horiz.io | N°1 en Calcul Rendement Locatif & Gestion Locative'

onMounted(() => {
  useTracking().trackAfterMounted('Home loaded')
})

useMeta({ title, appleItunesApp: 'app-id=1596721394' })

useHead({
  title,
  meta: [
    {
      hid: 'google-site-verification',
      name: 'google-site-verification',
      content: 's8Iqp45ZkONySMOKGtDFV-uC2tJPL7bpZ2x2m1eWjHc',
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: config.public.hpBaseUrl,
    },
  ],
})

useJsonld([
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Horiz',
    alternateName: 'Rendement Locatif',
    url: config.public.hpBaseUrl,
    logo: `${config.public.hpBaseUrl}/imgs/logo.png`,
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: 'Horiz',
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url: config.public.hpBaseUrl,
  },
  {
    '@context': 'http://www.schema.org',
    '@type': 'Product',
    name: 'Horiz',
    brand: ' Horiz',
    image: `${config.public.hpBaseUrl}/imgs/logo.png`,
    description: "/html/head/meta[@name='description']/@content",
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: '5',
      reviewCount: '62',
      bestRating: '5 ',
    },
  },
])
</script>
